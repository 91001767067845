<template>
  <div>
    <WelcomeReferralSpecialistPopup
      v-if="typeNotification && typeNotification === 'welcome_referral_specialist'"
      @on-close="typeNotification = null"
    />
    <CongratulationsForReferralSpecialistRegister
      v-if="typeNotification && typeNotification === 'referrer_congratulations_for_referral_specialist_register'"
      @on-close="typeNotification = null"
    />
  </div>
</template>

<script>
import { mainApi } from '../../api';
import { parseConfig } from '../../utils/json-config';
import { getServerErrors } from '../../utils/validate';

export default {
  name       : 'App',
  components : {
    WelcomeReferralSpecialistPopup               : () => import('./components/WelcomeReferralSpecialistPopup.vue'),
    CongratulationsForReferralSpecialistRegister : () => import('./components/CongratulationsForReferralSpecialistRegister.vue')
  },
  data() {
    return {
      typeNotification: null
    };
  },
  created() {
    const config = parseConfig();
    if (config.isUserAuthorized) {
      this.init();
    }
  },
  methods: {
    async init(isErrorToken) {
      try {
        await mainApi.modules.user.getAccountInfo().then((data) => {
          const filteredNotifications = data.notifications && data.notifications.length
            ? data.notifications.filter((el) => !el.read_at)
            : null;
          const notification = filteredNotifications && filteredNotifications.length
            ? filteredNotifications[0]
            : null;

          this.typeNotification = notification && notification.type
            ? notification.type
            : null;
        });
      } catch (err) {
        const serverErrors = getServerErrors(err);

        // При первой ошибке токена отправляем форму ещё раз
        if (!isErrorToken && serverErrors && serverErrors.token) {
          await this.init(true);
        }
      }
    },

    async readNotification(id, isErrorToken) {
      try {
        const response = await mainApi.modules.user.readNotification(id);
        if (response) {
          this.typeNotification = null;
        }
      } catch (err) {
        const serverErrors = getServerErrors(err);

        // При первой ошибке токена отправляем форму ещё раз
        if (!isErrorToken && serverErrors && serverErrors.token) {
          await this.init(id, true);
        }
      }
    }
  }
};
</script>
