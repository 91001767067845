import Vue from 'vue';
import App from './App.vue';

const userNotifications = document.querySelector('#user-notifications');
if (userNotifications) {
  new Vue({
    el     : '#user-notifications',
    render : (h) => h(App)
  });
}
