var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.typeNotification &&
      _vm.typeNotification === "welcome_referral_specialist"
        ? _c("WelcomeReferralSpecialistPopup", {
            on: {
              "on-close": function($event) {
                _vm.typeNotification = null
              }
            }
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.typeNotification &&
      _vm.typeNotification ===
        "referrer_congratulations_for_referral_specialist_register"
        ? _c("CongratulationsForReferralSpecialistRegister", {
            on: {
              "on-close": function($event) {
                _vm.typeNotification = null
              }
            }
          })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }